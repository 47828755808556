<template>
  <div class="page-thank-you">
    <div class="container">
      <h1 class="thank-you__title">Muchas gracias por su solicitud!</h1>
      <p class="thank-you__body">
        Los profesionales se pondran en contacto contigo en la mayor brevedad
        posible. Asi podeis empezar rapidamente con su proyecto!
      </p>
    </div>

    <div class="container">
      <div id="usps" class="usps__container">
        <h2 class="usps__title">Algunos consejos</h2>
        <Usps :usps="usps" />
      </div>
    </div>
  </div>
</template>

<script>
import Usps from 'chimera/all/themes/blueflow/components/Usps'
import AbstractPage from 'chimera/all/components/page/AbstractPage'

export default {
  components: {
    Usps,
  },

  extends: AbstractPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Muchas gracias por su solicitud!',
      headDescription:
        'Introduce tu codigo postal y compara gratis hasta 6 empresas de tu provincia. El mejor precio para tu proveedor de paneles solares. Ahorra hasta un 40%. 100% Gratis.',
      path: '/solicitudes-de-cotizacion/solicitud-completada',
      usps: [
        {
          text: 'Asegurate de tener tu teléfono y tu agenda a mano. Los profesionales contactaran contigo de forma telefónica.',
          logo: require('chimera/all/themes/blueflow/images/usps/calendar.svg'),
        },
        {
          text: 'No mires únicamente el precio. Compara en base a: calidad, servicio y confianza.',
          logo: require('chimera/all/themes/blueflow/images/usps/offer.svg'),
        },
        {
          text: 'Tómate tu tiempo para decidirte. Da tiempo a la empresa para que pueda empezar tu trabajo.',
          logo: require('chimera/all/themes/blueflow/images/usps/safety.svg'),
        },
      ],
    }
  },
}
</script>
